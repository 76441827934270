import React from "react";
import styled from "styled-components";
import background from "assets/header_image.jpg";
import logo from "assets/old_white_logo.png";
import Button from "components/Button";

const HeaderContainer = styled.div`
  background-image: radial-gradient(
      at 2% 93%,
      hsla(292, 80%, 32%, 0.6) 0,
      transparent 85%
    ),
    radial-gradient(at 0% 0%, hsla(181, 93%, 46%, 0.5) 0, transparent 50%),
    radial-gradient(at 30% 1%, hsla(322, 67%, 30%, 1) 0, transparent 50%),
    radial-gradient(at 30% 85%, hsla(314, 24%, 86%, 0.25) 0, transparent 50%),
    url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right 20%;
  color: var(--white);
  padding: 120px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    max-width: 400px;
    width: 80%;
  }

  h1 {
    margin-bottom: 0;
    font-weight: 700;
  }

  p {
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 1.5em;
  }

  @media (min-width: 1024px) {
    align-items: left;
    text-align: left;
  }

  @media (max-width: 576px) {
    padding: 60px 30px;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <img src={logo} alt="SummerIS" />
      <h1>Hai să dăm naștere unor noi amintiri!</h1>
      <p>Perioada de înscrieri s-a terminat! Ne vedem la ediția viitoare!</p>
      <Button link="/elevi_admisi.pdf">Vezi elevii admiși</Button>
    </HeaderContainer>
  );
}

export default Header;
