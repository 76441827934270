import React from "react";
import styled from "styled-components";

const SectionContainer = styled.section`
  padding: 75px 60px;
  text-align: center;

  h1 {
    margin-top: 0;
    font-weight: 600;
    margin-bottom: 60px;
  }

  > div {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(1, 1fr);

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, 1fr);
    }

    div {
      p {
        margin: 0;
      }
      span {
        font-weight: 700;
        font-size: 3em;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 50px 30px;

    > div {
      gap: 25px;
    }
  }
`;

function RegistrationSteps() {
  return (
    <SectionContainer>
      <h1>Cum poți participa?</h1>
      <div>
        <div>
          <span>1</span>
          <p>Completezi formularul de înscriere, conform instrucțiunilor</p>
        </div>
        <div>
          <span>2</span>
          <p>
            Aștepți să fii contactat de către un voluntar după încheierea
            înscrierilor
          </p>
        </div>
        <div>
          <span>3</span>
          <p>Dacă ești acceptat, achiți taxa de înscriere de 250 de lei</p>
        </div>
        <div>
          <span>4</span>
          <p>Trimiți documentele necesare, menționate de voluntar</p>
        </div>
      </div>
    </SectionContainer>
  );
}

export default RegistrationSteps;
