import React from "react";
import styled from "styled-components";
import photo from "assets/balena.jpg";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 75px 60px;
  gap: 50px;
  align-items: center;

  h1 {
    margin-top: 0;
    font-weight: 600;
  }

  div {
    flex: 2 1 0;
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    max-width: 750px;
    border-radius: 6px;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    div {
      flex: 2 1 0;
      width: 50%;
    }

    img {
      width: 40%;
    }
  }

  @media (min-width: 1440px) {
    div {
      width: 60%;
    }
    img {
      width: 30%;
    }
  }

  @media (max-width: 576px) {
    padding: 50px 30px;
  }
`;

function InfoPlace() {
  return (
    <SectionContainer>
      <img src={photo} alt="studenți adunați la balenă" />
      <div>
        <h1>Experimentează viața de student!</h1>
        <p>
          Timp de o săptămână, în perioada 26 iulie – 1 august, vei experimenta
          viața de cămin, vei lua masa la cantină, vei merge la cursuri și vei
          petrece ca un adevărat student! Fiind cel mai bun prilej de a
          socializa, farmecul atmosferei din campus constă în ocazia de a avea o
          experiență de neuitat, probabil cea mai tare din această vară.
        </p>
      </div>
    </SectionContainer>
  );
}

export default InfoPlace;
