import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.a`
  color: inherit;
  font-size: 1.5em;
  background-color: rgba(0, 0, 0, 0.35);
  width: fit-content;
  padding: 10px 30px;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

function Button({ children, link }) {
  return (
    <ButtonContainer
      href={link}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {children}
    </ButtonContainer>
  );
}

export default Button;
