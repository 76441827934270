import React from "react";
import styled from "styled-components";

import asii from "assets/asii.png";
import asd from "assets/asd.png";
import asfi from "assets/asfi.png";
import aspp from "assets/aspp.png";
import prime from "assets/prime.png";

const SectionContainer = styled.section`
  padding: 75px 60px;

  h1 {
    margin-top: 0;
    font-weight: 600;
    text-align: center;
    margin-bottom: 60px;
  }

  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
    gap: 30px;
    place-content: center;

    @media (min-width: 1440px) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @media (max-width: 576px) {
      grid-template-columns: unset;
    }
  }

  @media (max-width: 576px) {
    padding: 50px 30px;
  }
`;

const Faculty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--white);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.05),
    0 30px 60px -30px rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(
      at 40% 20%,
      hsla(292, 81%, 32%, 0.05) 0,
      transparent 50%
    ),
    radial-gradient(at 80% 0%, hsla(181, 94%, 46%, 0.05) 0, transparent 50%),
    radial-gradient(at 0% 50%, hsla(323, 68%, 30%, 0.05) 0, transparent 50%),
    radial-gradient(at 80% 50%, hsla(314, 57%, 22%, 0.05) 0, transparent 50%);
  border: 1px solid var(--purple-section);

  h2 {
    font-size: 1.15em;
    font-weight: 600;
    text-align: center;
    margin: 0;
  }

  div {
    height: 200px;
    display: grid;
    place-content: center;
  }

  img {
    height: 100px;
    flex-shrink: 0;
  }

  p {
    font-size: 0.95em;
  }
`;

function Faculties() {
  return (
    <SectionContainer>
      <h1>Descoperă facultățile</h1>
      <div>
        <Faculty>
          <div>
            <img src={aspp} alt="Asociația Studenților Psihologi și Pedagogi" />
          </div>
          <h2>Psihologie și Științe ale Educației</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            euismod eros turpis, non porta odio luctus non. Aliquam porta urna
            at nulla malesuada, a mollis est hendrerit. Mauris tincidunt ligula
            at justo ultricies, id commodo purus faucibus.
          </p> */}
        </Faculty>
        <Faculty>
          <div>
            <img src={asii} alt="Asociația Studenților Informaticieni Ieșeni" />
          </div>
          <h2>Informatică</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            euismod eros turpis, non porta odio luctus non. Aliquam porta urna
            at nulla malesuada, a mollis est hendrerit. Mauris tincidunt ligula
            at justo ultricies, id commodo purus faucibus.
          </p> */}
        </Faculty>
        <Faculty>
          <div>
            <img src={asfi} alt="Asociația Studenților Francofoni din Iași" />
          </div>
          <h2>Litere</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            euismod eros turpis, non porta odio luctus non. Aliquam porta urna
            at nulla malesuada, a mollis est hendrerit. Mauris tincidunt ligula
            at justo ultricies, id commodo purus faucibus.
          </p> */}
        </Faculty>
        <Faculty>
          <div>
            <img src={asd} alt="Asociația Studenților la Drept" />
          </div>
          <h2>Drept</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            euismod eros turpis, non porta odio luctus non. Aliquam porta urna
            at nulla malesuada, a mollis est hendrerit. Mauris tincidunt ligula
            at justo ultricies, id commodo purus faucibus.
          </p> */}
        </Faculty>
        <Faculty>
          <div>
            <img src={prime} alt="PRIME Iași" />
          </div>
          <h2>Comunicare și Relații Publice</h2>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
            euismod eros turpis, non porta odio luctus non. Aliquam porta urna
            at nulla malesuada, a mollis est hendrerit. Mauris tincidunt ligula
            at justo ultricies, id commodo purus faucibus.
          </p> */}
        </Faculty>
      </div>
    </SectionContainer>
  );
}

export default Faculties;
