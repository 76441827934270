import React from "react";
import styled from "styled-components";

import Header from "./Header";
// import Roca from "./Roca";
import About from "./About";
import RegistrationSteps from "./RegistrationSteps";
import InfoPlace from "./InfoPlace";
import Faculties from "./Faculties";
import Contact from "./Contact";
import Footer from "components/Footer";

const Container = styled.div`
  width: 100%;
  box-shadow: -4px 3px 15px 0 rgba(0, 83, 159, 0.1);
  /* max-width: 940px; */

  @media (min-width: 1024px) {
    /* max-width: 1230px; */
  }

  @media (min-width: 1440px) {
    max-width: 1630px;
  }
`;

function Home() {
  return (
    <Container>
      <Header />
      {/* <Roca /> */}
      <About />
      <RegistrationSteps />
      <InfoPlace />
      <Faculties />
      <Contact />
      <Footer />
    </Container>
  );
}

export default Home;
