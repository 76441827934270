import React from "react";
import Button from "components/Button";
import styled from "styled-components";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 75px 60px;

  h1 {
    margin: 0;
    font-weight: 600;
  }

  p {
    margin: 20px 0 30px 0;
  }

  div {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
  }

  a {
    color: var(--white);
    font-size: 1.15em;
  }

  a:nth-child(1) {
    background-color: rgb(45, 134, 255);
  }

  a:nth-child(2) {
    margin-top: 20px;
    background-color: rgb(225, 48, 108);

    @media (min-width: 1024px) {
      margin-left: 20px;
      margin-top: 0;
    }
  }

  @media (max-width: 576px) {
    padding: 50px 30px;
  }
`;

function Contact() {
  return (
    <SectionContainer>
      <h1>Fii la curent cu noutățile!</h1>
      <p>
        Pe paginile noastre de Facebook și Instagram te vom ține la curent cu
        toate noutățile! Dacă ai întrebări, nu ezita să ne contactezi!
      </p>
      <div>
        <Button link="https://www.facebook.com/uvesummeris/">Facebook</Button>
        <Button link="https://www.instagram.com/uve.summeris/">
          Instagram
        </Button>
      </div>
    </SectionContainer>
  );
}

export default Contact;
