import React from "react";
import styled from "styled-components";
import photo from "assets/bcu.jpg";

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 75px 60px;
  gap: 50px;
  align-items: center;

  h1 {
    margin-top: 0;
    font-weight: 600;
  }

  div {
    flex: 2 1 0;
    text-align: center;
  }

  img {
    object-fit: cover;
    width: 100%;
    max-width: 750px;
    border-radius: 6px;
    box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
      0 30px 60px -30px rgba(0, 0, 0, 0.3);
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    div {
      flex: 2 1 0;
      width: 50%;
    }

    img {
      width: 40%;
    }
  }

  @media (min-width: 1440px) {
    div {
      width: 60%;
    }
    img {
      width: 30%;
    }
  }

  @media (max-width: 576px) {
    padding: 50px 30px;
  }
`;

function About() {
  return (
    <SectionContainer>
      <div>
        <h1>Universitatea de Vară pentru Elevi „SummerIS”</h1>
        <p>
          Cea mai tare aventură a verii, unde viața de student din cadrul
          Universității „Alexandru Ioan Cuza” din Iași e prezentată cu tot ce
          presupune ea. Nopți nedormite, examene, proiecte, oboseală, petreceri,
          prietenii sunt doar câteva dintre lucrurile pe care elevii de clasa a
          XI-a le vor experimenta în cele 7 zile ale proiectului.
        </p>
        <p>
          Elevii vor merge la cursurile facultății alese, vor fi cazați în
          campus, vor lua mesele la cantină și vor avea ocazia să participe la
          diverse activități și joculețe pregătite special pentru ei.
        </p>
      </div>
      <img src={photo} alt="elevii la ceremonia de deschidere" />
    </SectionContainer>
  );
}

export default About;
