import React from "react";
import styled from "styled-components";

import bzi from "assets/bzi.jpg";

const FooterContainer = styled.footer`
  background: rgba(33, 33, 33, 0.75);
  color: rgba(250, 250, 250, 0.75);
  padding: 20px;
  text-align: center;

  > div:nth-child(2) {
    margin-top: 20px;
    font-size: 0.8em;
    font-weight: 300;
    text-transform: uppercase;

    img {
      margin-top: 7px;
      max-width: 175px;
      width: 90%;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      opacity: 90%;

      :hover {
        opacity: 100%;
      }
    }
  }
`;

function Footer() {
  return (
    <FooterContainer>
      <div>&copy; 2021 Universitatea de Vară pentru Elevi „SummerIS”</div>
      <div>
        <div>Partener media</div>
        <a
          href="https://www.bzi.ro/"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          <img src={bzi} alt="Bună Ziua Iași" />
        </a>
      </div>
    </FooterContainer>
  );
}

export default Footer;
